import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
// context
import { DeviceContext } from '~/contexts/DeviceContext';

// types
import type { MultiPageHeroVariantProps } from '~/components/publicWebsiteV2/common/MultiPageHeroContainer/types';

// components
import WebsiteMarquee from '~/components/publicWebsiteV2/common/MultiPageHeroContainer/components/MultiPageHeroMarquee/WebsiteMarquee';

// utils
import { getWebsitePlaceholderAsset } from '~/components/manage/EditWebsite/common/HeroCustomizer/utils';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';
import { appendHeightParam } from '~/components/publicWebsiteV2/util/getRelativeImgSize';

// styles
import { BaseHero } from '~/components/publicWebsiteV2/common/MultiPageHeroContainer/MultiPageHeroContainer.styles';
import { fetchImageMetadata } from '~/api/image';
import type { MarqueeImage } from './WebsiteMarquee/WebsiteMarquee';

export type ImageOrDefaultImage = {
  url: string;
  height?: number;
  width?: number;
  uuid?: string | null;
};

const MultiPageHeroMarquee: React.FC<MultiPageHeroVariantProps> = ({
  images,
  inPreview,
  overrideMobileHeight,
}) => {
  const { device } = useContext(DeviceContext);
  const [marqueeImages, setMarqueeImages] = useState<MarqueeImage[]>([]);
  const isDesktop = inPreview === 'DESKTOP' || (!inPreview && device?.isDesktop());

  // Combine the user images with the default images where needed
  const imagesToShow = useMemo(() => {
    const slideshowImages: ImageOrDefaultImage[] = [
      { url: getWebsitePlaceholderAsset('MARQUEE', isDesktop, 1), height: 1766, width: 2650 },
      { url: getWebsitePlaceholderAsset('MARQUEE', isDesktop, 2), height: 2650, width: 1767 },
      { url: getWebsitePlaceholderAsset('MARQUEE', isDesktop, 3), height: 1766, width: 2650 },
      { url: getWebsitePlaceholderAsset('MARQUEE', isDesktop, 4), height: 1710, width: 1710 },
    ];
    if (images && images.length > 0) {
      images.forEach((image) => {
        if (image.position && image.image_uuid) {
          slideshowImages[image.position - 1] = {
            url: image.image_url,
            uuid: image.image_uuid,
            height: image.height,
            width: image.width,
          };
        }
      });
    }
    return slideshowImages;
  }, [images, isDesktop]);

  const containerRef = useRef(null);
  const { height: containerHeight } = useContainerDimensions(containerRef);

  useEffect(() => {
    // Check that the imagesToShow have the required data, otherwise fetch it
    const imgsHaveRequiredData = imagesToShow.every((img) => img.height && img.width);
    if (imgsHaveRequiredData) {
      setMarqueeImages(
        imagesToShow.map(({ url, height, width }) => ({
          url: appendHeightParam({ src: url, height: containerHeight, device }),
          height: height as number,
          width: width as number,
        }))
      );
    } else {
      const promises = imagesToShow.map(({ uuid, url, height, width }) => {
        const hasHeightAndWidth = Boolean(height && width);
        const sizedUrl = appendHeightParam({ src: url, height: containerHeight, device });
        if (uuid && !hasHeightAndWidth)
          return fetchImageMetadata(uuid).then((fetchedMeta) => ({
            url: sizedUrl,
            height: fetchedMeta.height,
            width: fetchedMeta.width,
          }));
        // Else it's a default image, so we don't need to fetch metadata
        return Promise.resolve({
          url: sizedUrl,
          height: height as number,
          width: width as number,
        });
      });
      Promise.all(promises)
        .then((wImageViews) => setMarqueeImages(wImageViews))
        .catch(() => undefined);
    }
  }, [imagesToShow]);

  return (
    <BaseHero
      overrideMobileHeight={overrideMobileHeight}
      data-testid="MultiPageHeroMarquee"
      ref={containerRef}
    >
      <WebsiteMarquee inPreview={inPreview} marqueeImages={marqueeImages} />
    </BaseHero>
  );
};

export default MultiPageHeroMarquee;
